import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import { useProducte } from 'hooks/3cat/useProducte';
import { typesViewport, useViewport } from 'hooks/useViewport';
import __isEmpty from 'lodash/isEmpty';
import Icona from '../ui/Icona';
import styles from './social.module.scss';

export default function Social(props) {
    const {
        tipus,
        className,
        estil,
        showTitol,
        urlFacebook,
        urlTwitter,
        urlInstagram,
        urlYoutube,
        urlSpotify,
        showPodcast,
        podcastSpotify,
        podcastApple,
        podcastAmazon,
        urlIVoox,
        urlRSS
    } = props;

    const { viewport } = useViewport();

    const midaViewport = viewport !== typesViewport.VP_MOBILE ? 48 : 32;
    const mida = tipus === 'programa' ? midaViewport : null;

    const stylesSocial = clsx({
        [styles.social]: true,
        [className]: !__isEmpty(className),
        [styles[estil]]: !__isEmpty(estil)
    });

    const styleHeader = clsx({
        [styles.xarxesHeader]: true,
        [styles[`${estil}-header`]]: !__isEmpty(estil)
    });

    const { isWebviewActive } = useProducte();

    if (isWebviewActive) {
        return <></>;
    } else {
        return (
            <>
                {showTitol &&
                    (urlFacebook ||
                        urlTwitter ||
                        urlInstagram ||
                        urlYoutube ||
                        urlSpotify ||
                        (showPodcast == 'SI' && (podcastSpotify || podcastApple || podcastAmazon || urlIVoox || urlRSS))) && (
                        <div id='xarxes' aria-label='Xarxes socials del programa' className={styleHeader}>
                            Troba&apos;ns a:<span className='sr-only'>les següents xarxes socials</span>
                        </div>
                    )}

                {(urlFacebook ||
                    urlTwitter ||
                    urlInstagram ||
                    urlYoutube ||
                    urlSpotify ||
                    (showPodcast == 'SI' && (podcastSpotify || podcastApple || podcastAmazon || urlIVoox || urlRSS))) && (
                    <ul className={stylesSocial} aria-labelledby='xarxes'>
                        {urlFacebook && (
                            <li>
                                <Enllac href={urlFacebook} novaFinestra={true} ariaLabel='Facebook'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='facebook' alt='Facebook' />
                                </Enllac>
                            </li>
                        )}
                        {urlTwitter && (
                            <li>
                                <Enllac href={urlTwitter} novaFinestra={true} ariaLabel='X'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='x' alt='X' />
                                </Enllac>
                            </li>
                        )}
                        {urlInstagram && (
                            <li>
                                <Enllac href={urlInstagram} novaFinestra={true} ariaLabel='Instagram'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='instagram' alt='Instagram' />
                                </Enllac>
                            </li>
                        )}
                        {urlYoutube && (
                            <li>
                                <Enllac href={urlYoutube} novaFinestra={true} ariaLabel='Youtube'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='youtube' alt='Youtube' />
                                </Enllac>
                            </li>
                        )}
                        {urlSpotify && (
                            <li>
                                <Enllac href={urlSpotify} novaFinestra={true} ariaLabel='Spotify'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='spotify' alt='Spotify' />
                                </Enllac>
                            </li>
                        )}
                        {showPodcast == 'SI' && podcastSpotify && (
                            <li>
                                <Enllac href={podcastSpotify} novaFinestra={true} ariaLabel='Spotify'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='spotify' alt='Spotify Podcast' />
                                </Enllac>
                            </li>
                        )}
                        {showPodcast == 'SI' && podcastApple && (
                            <li>
                                <Enllac href={podcastApple} novaFinestra={true} ariaLabel='Apple podcast'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='applepodcast' alt='Apple Podcast' />
                                </Enllac>
                            </li>
                        )}
                        {showPodcast == 'SI' && podcastAmazon && (
                            <li>
                                <Enllac href={podcastAmazon} novaFinestra={true} ariaLabel='Amazon music'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='amazonmusic' alt='Amazon Music' />
                                </Enllac>
                            </li>
                        )}
                        {showPodcast == 'SI' && urlIVoox && (
                            <li>
                                <Enllac href={urlIVoox} novaFinestra={true} ariaLabel='iVoox'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='ivoox' alt='iVoox' />
                                </Enllac>
                            </li>
                        )}
                        {showPodcast == 'SI' && urlRSS && (
                            <li>
                                <Enllac href={urlRSS} novaFinestra={true} ariaLabel='RSS'>
                                    <Icona className={styles.iconaSocial} mida={mida} icona='rss' alt='RSS' />
                                </Enllac>
                            </li>
                        )}
                    </ul>
                )}
            </>
        );
    }
}

Social.defaultProps = {
    mida: '20',
    showTitol: true,
    urlFacebook: false,
    urlTwitter: false,
    urlInstagram: false,
    urlYoutube: false,
    urlSpotify: false,
    podcastSpotify: false,
    podcastApple: false,
    podcastAmazon: false,
    urlIVoox: false,
    urlRSS: false,
    showPodcast: false,
    isWebviewActive: false
};
